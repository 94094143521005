import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import PropTypes from 'prop-types';

import { env } from './src/environment';
import './src/styles/global.css';

const bugsnagClient = bugsnag({
  apiKey: env.bugsnagId,
  releaseStage: env.platform,
  beforeSend: report => {
    if (!env.isDeployed) {
      report.ignore();
    }
  },
});

bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

export const wrapRootElement = ({ element }) => (
  <ErrorBoundary>{element}</ErrorBoundary>
);

wrapRootElement.propTypes = {
  element: PropTypes.object,
};
