const platform = process.env.PLATFORM || 'local';
const apiPlatform = process.env.API_PLATFORM || 'staging';
const apiDomain = process.env.API_DOMAIN || 'staging.k8s.wizbii.us';

export const env = {
  applicationId: 'sso-internal',
  version: process.env.VERSION,
  platform,
  isDeployed: !!process.env.PLATFORM,
  apiPlatform,
  apiDomain,
  siteUrl: platform !== 'local' ? `https://sso.internal.${apiPlatform}` : '',
  api: {
    auth: `https://auth.${apiDomain}`,
  },
  bugsnagId: 'ff340b14abdcaf2cb8ae21f224522ebe',
};
