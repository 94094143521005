// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-internal-status-js": () => import("/usr/src/app/src/pages/_internal/status.js" /* webpackChunkName: "component---src-pages-internal-status-js" */),
  "component---src-pages-404-js": () => import("/usr/src/app/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/usr/src/app/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-unauthorized-user-js": () => import("/usr/src/app/src/pages/unauthorizedUser.js" /* webpackChunkName: "component---src-pages-unauthorized-user-js" */)
}

